const env = process.env.ENVIRONMENT

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['pl', 'en', 'de', 'ar'],
    localeDetection: false
  },
  backend: {
    projectId: '496af1cf-3c58-4b88-a457-60205804ee85',
    referenceLng: 'pl',
    version:
      env === 'production' || env === 'staging' ? 'production' : 'latest',
    allowedAddOrUpdateHosts: ['localhost']
  },
  use: [require('i18next-locize-backend/cjs')],
  ns: [
    'cancellation',
    'checkout',
    'common',
    'contact',
    'faq',
    'home',
    'landing',
    'navipay',
    'opinions',
    'parkings',
    'legia',
    'lot172',
    'torwar',
    'autopay',
    'popups'
  ],
  serializeConfig: false,
  react: {
    useSuspense: false,
    wait: true
  }
}
